<template>
  <v-theme-provider>
    <base-section
      id="commissioner"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Struktur Perusahaan" />
            <base-body space="0">
              <v-img
                :src="require(`@/assets/structure-management-2025.jpg`)"
              />
               <!-- <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="card in cards"
                    :key="card.name"
                    md="4"
                    xs="12"
                  >
                    <div 
                      data-aos="fade-in"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-once="true"
                      data-aos-anchor-placement="top-center">
                    <v-hover v-slot="{ hover }">
                    <v-card
                    outlined>
                      <v-img
                        :src="require(`@/assets/${card.src}`)"
                        class="white--text align-end"
                        height="350px"
                        :gradient="hover ? '' : 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'"
                        :alt="card.name"
                      >
                        <v-card-title class="body-1" v-text="card.name"></v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ card.title }}
                      </v-card-actions>
                    </v-card>
                    </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-container> -->
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionCommissioner',
    data: () => ({
            posts: [],
            cards: [],
        }),
  }
</script>
